function openSizeAchatExpressV1(produit_id, type, lookbook) {
    // Reset the wishlist heart state to ensure it is always empty.
    resetWishlistState();

    // Check if the current device is mobile.
    var isMobile = $("body").hasClass("mobile")    
    // If produit_id is undefined, get the product ID from the data attribute.
    if (typeof produit_id === "undefined") {
        produit_id = $(thisdata('produit-id'));
    }

    // Construct the URL for the AJAX request.
    var url = constructUrl(produit_id, type);
    
    // Perform the AJAX request to fetch product data.
    fetchProductData(url, function(product) {
        if (typeof product.id !== "undefined") {
            // Handle the product data if the product ID is valid.
            handleProductData(product, isMobile, lookbook);
        } else {
            // Show an error message if the product ID is not found.
            alert(Translator.translate("error"));
        }
    });
}

function resetWishlistState() {
    // Remove the 'existToWishlistButton' class from wishlist items.
    $("#lightboxAchatExpressV1Brand .wishlist a").removeClass('existToWishlistButton');
}

function constructUrl(produit_id, type) {
    // Construct the base URL for the AJAX request.
    var url = path_relative_root + "ajax_get_product_data.php?id=" + produit_id;
    
    // Append the 'type' parameter to the URL if defined.
    if (typeof type !== "undefined") {
        url += "&from=" + type;
    }
    return url;
}

function fetchProductData(url, callback) {
    // Perform the AJAX request with the given URL and callback function.
    $.ajax({
        url: url,
        success: callback
    });
}

function handleProductData(product, isMobile, lookbook) {
    // Hide color sizes initially.
    hideColorSizesV1();
    
    // Update the product gallery with images.
    updateGallery(product);
    
    // Update the displayed prices based on the product data.
    updatePrices(product);
    
    // Update the brand name.
    updateBrand(product);
    
    // Update the product title, subtitle, and URL.
    updateProductDetails(product);
    
    // Update the wishlist product ID.
    updateWishlist(product);
    
    // Update the alert stock and basket product IDs.
    updateProductIds(product);

    // Update the UI based on the device type (mobile or desktop).
    if (isMobile) {
        updateMobileView(product);
    } else {
        updateDesktopView(product);
    }
    
    // Toggle the lightbox display based on the device type.
    toggleLightboxDisplay(isMobile);
    
    // Update the lookbook state and UI based on the 'lookbook' parameter.
    updateLookbookState(product, lookbook);
}

function updateGallery(product) {
    // Clear the existing gallery.
    $("#lightboxAchatExpressV1 .productVisualMulti .swiper-wrapper").html('');
    
    // Add appropriate class based on the number of gallery images.
    if (product.gallery.length > 1) {
        $('#lightboxAchatExpressV1 .productVisualMulti').addClass("productSwiper");
    } else {
        $('#lightboxAchatExpressV1 .productVisualMulti').removeClass("product");
    }
    
    // Loop through the gallery and append images.
    for (var i = 0; i < product.gallery.length; i++) {
        var gallery = product.gallery[i];
        var img = '<div class="wrapper_img swiper-slide"><a href="' + product.url + '.html' + '"><img src="' + gallery[0].replace('medium', 'midmedium') + '" data-alt="' + product.alt + '"></div>';
        $("#lightboxAchatExpressV1 .productVisualMulti .swiper-wrapper").append(img);
    }
    
    // Reload the lightbox swiper.
    reloadLightboxSwiper();
}

function updatePrices(product) {
    // Initialize price variables.
    var unit_price = 0;
    var prices = "";
    
    // Determine the displayed prices based on the product's connected status and discount.
    if (product.connected) {
        if (product.prices.privateSale > 0) {
            prices += product.prices.privateSale_formatted;
            unit_price = product.prices.privateSale;
            if (product.prices.discount > 0) {
                prices += product.prices.discount_formatted.replace('price', 'price crossed');
            } else {
                prices += product.prices.sale_formatted.replace('price', 'price crossed');
            }
        } else if (product.prices.discount > 0) {
            unit_price = product.prices.discount;
            prices += product.prices.discount_formatted;
            prices += product.prices.sale_formatted.replace('price', 'price crossed');
        } else {
            unit_price = product.prices.sale;
            prices += product.prices.sale_formatted;
        }
    } else {
        if (product.prices.discount > 0) {
            unit_price = product.prices.discount;
            prices += product.prices.discount_formatted;
            prices += product.prices.sale_formatted.replace('price', 'price crossed');
        } else {
            unit_price = product.prices.sale;
            prices += product.prices.sale_formatted;
        }
    }

    // Set the unit price in the hidden input field.
    $("#price_aev1").val(unit_price);
    
    // Append ecotaxe if it exists.
    if (product.ecotaxe > 0) {
        prices += '<span class="ecotaxe">' + Translator.translate('product_ecoparticipation') + ' ' + product.ecotaxe_formatted + '</span>';
    }
    
    // Update the price display.
    $("#lightboxAchatExpressV1 .prices").html(prices)
}

function updateBrand(product) {
    // Update the brand name if it exists    
    if (product.brand.nom) {
        $("#lightboxAchatExpressV1 .brand .container_marques").html(product.brand.nom);
    }
}

function updateProductDetails(product) {
 // Update the product URL, title, and subtitle    
    $("#lightboxAchatExpressV1 #urlFp").attr("href", product.url + ".html");
    $("#lightboxAchatExpressV1 .title").html(product.title);
    $("#lightboxAchatExpressV1 .subtitle").html(product.subtitle);
}

function updateWishlist(product) {
    // Update the wishlist product ID.
    document.querySelector("#lightboxAchatExpressV1Brand .wishlist a").dataset.produitid = product.id;
}

function updateProductIds(product) {
    // Update the IDs for alert stock and basket.
    document.getElementById("bloc_add_alert_aev1").dataset.produitid = product.id;
    document.getElementById("bloc_add_basket_aev1").dataset.produitid = product.id;
    document.getElementById("formAchatExpressV1").dataset.produitid = product.id;
}

function updateMobileView(product) {
    // Update colors and sizes for mobile view.
    updateColorMobile(product);
    updateSizeMobile(product);
}

function updateDesktopView(product) {
    // Clear existing color and size options.
    $("#lightboxAchatExpressV1ColorSize").html("");
    
    // Update colors and sizes for desktop view.
    updateColorDesktop(product);
    updateSizeDesktop(product);
    
    // Update characteristics and description if available.
    if (typeof product.characteristics_content !== "undefined") {
        $("#lightboxAchatExpressV1Carac").html(product.characteristics_content);
        $("#lightboxAchatExpressV1Carac .description").html(product.description);
    }
}

function toggleLightboxDisplay(isMobile) {
    // Show or hide the lightbox based on the device type ( or desktop).
    if (isMobile) {
        $('#lightboxAchatExpressV1').slideDown();
        $('body').addClass('noscroll');
        $('#shade').addClass('actif');
    } else {
        $('#lightboxAchatExpressV1').addClass("if");
        openMultiShad("lightboxAchatExpressV1");
    }
}

function updateLookbookState(product, lookbook) {
    // Set the "see product" button URL.
    $("#bloc_see_product_aev1").attr("href", product.url + ".html");
    
    // Adjust the UI based on lookbook parameter.
    if (typeof lookbook !== "undefined" && lookbook) {
        $("#lightboxAchatExpressV1").addClass("lookbook");
        $("#bloc_add_basket_aev1").hide();
        $("#bloc_add_alert_aev1").hide();
        $("#size_not_available_aev1").hide();
        $("#lightboxAchatExpressV1 .loader").hide();
        $("#bloc_see_product_aev1").show();
    } else {
        $("#lightboxAchatExpressV1").removeClass("lookbook");
        $("#lightboxAchatExpressV1 .loader").show();
        $("#bloc_see_product_aev1").hide();
        $("#bloc_add_basket_aev1").show();
    }
}

function hideAchatExpressV1() {
    var isMobile = $("body").hasClass("mobile");
    $("#shade").removeClass("visible actif isExpress");
    if (isMobile) {
        $('#lightboxAchatExpressV1').slideUp();
        $('body').removeClass('noscroll');
        $('#lightboxAchatExpressV1').removeClass("for_tag_button");
        $("#size_not_available_aev1").hide();
        $("#bloc_add_alert_aev1").hide();
        $('#bloc_add_basket_aev1').show();
        if ($('#display_sizes').hasClass('error')) {
            $('#display_sizes').removeClass('error');
        }
    } else {
        $('#lightboxAchatExpressV1').removeClass("actif");
        closeMultiShad("lightboxAchatExpressV1");
        $("#bloc_add_alert_aev1").hide();
        $("#bloc_add_basket_aev1").addClass('disabled');
    }
}

function reloadLightboxSwiper() {
    var isMobile = $("body").hasClass("mobile");
    if (isMobile) {
        setTimeout(function () {
            if ($("#lightboxAchatExpressV1Pictures .product_swiper_new .swiper-slide").length > 2) {
                var product_swiper_new = new Swiper("#lightboxAchatExpressV1Pictures .product_swiper_new", {
                    slidesPerView: 2.2,
                    spaceBetween: 6,
                    slidesOffsetAfter: 30,
                });
            }
        }, 1000);
    }
}

function updateColorDesktop(product)
{
    // COLORS - DESKTOP
    var selectColors = '<div class="itm_form_group">';
    selectColors += '<div id="ligne_couleur_' + product.id + '" class="ligne_form productColorFieldset">';
    selectColors += '<div class="prod_listes right_element_prod">';
    selectColors += '<div class="choices_list fp_color_swiper"><div class="swiper-wrapper">';
    if (product.colorsRegroupRef.length > 0) {
        var colors = product.colorsRegroupRef;
    } else {
        var colors = product.colors;
    }
    if (colors.length > 0) {
        for (i = 0; i < colors.length; i++) {
            var color = colors[i];
            selectColors += '<div data-color="' + color.label + '" class="swiper-slide form_itm check color">';
            selectColors += '<input id="color_' + product.id + '_' + color.id + '" type="radio" name="itm_color_' + product.id + '" value="' + color.id + '" ';
            if (color.product_id !== "undefined") {
                selectColors += ' onchange="openSizeAchatExpressV1(' + color.product_id + ')" ';
            }
            selectColors += 'data-nom="' + color.label + '" ';
            if (product.firstColorId == color.id) {
                selectColors += 'checked ';
            }
            selectColors += 'class="input_check radio prodColor color_' + product.id + '">';
            selectColors += '<label for="color_' + product.id + '_' + color.id + '" title="' + color.label + '" class="preview_color" style="background-color: ' + color.hexa + ';">' + color.label + '</label>';
            selectColors += '</div>';
        }
    }
    selectColors += '</div></div></div>';
    selectColors += '</select>';
    $("#lightboxAchatExpressV1ColorSize").append(selectColors);
}

function updateColorMobile(product) {
    // COLORS - MOBILE
    var selectColors = '';
    var colors = product.colors;
    if (product.colorsRegroupRef.length > 0) {
        var colors = product.colorsRegroupRef;
    }
    for (i = 0; i < colors.length; i++) {
        var color = colors[i];
        selectColors += '<div data-color="' + color.label + '" class="form_itm check color" ';
        if (typeof color.product_id !== "undefined") {
            selectColors += 'onclick="openSizeAchatExpressV1(' + color.product_id + ')"';
        }
        selectColors += '>';
        if (typeof color.src !== "undefined") {
            selectColors += '<img src="' + color.src + '">';
        }
        selectColors += '<input id="color_' + product.id + '_' + color.id + '" type="radio" name="itm_color_' + product.id + '" value="' + color.id + '" ';
        if (color.id == product.firstColorId) {
            selectColors += 'checked ';
            $("#display_colors").html('<label class="preview_color"><span class="color_previsu" style="background-color: ' + color.hexa + ';"></span>' + color.label + '</label>');
        }
        if (typeof color.src !== "undefined") {
            selectColors += 'data-pic="' + color.src + '" ';
        }
        selectColors += 'data-nom="' + color.label + '" class="input_check radio prodColor">';
        selectColors += '<label for="color_' + product.id + '_' + color.id + '" title="' + color.label + '" class="preview_color" style="background-color: ' + color.hexa + ';"></label>';
        selectColors += '</div>';
    }
    if (colors.length == 1) {
        $("#display_colors").addClass('disabled').attr('onclick', "");
    } else {
        $("#display_colors").removeClass('disabled').attr('onclick', "displayColorSizesV1('color')");
    }
    
    $("#choose_colors .choices_list").html(selectColors);
}

function updateSizeDesktop(product) {
    // SIZES - DESKTOP
    var selectSizes = '<div class="ligne_form productSizeFieldset">';
    selectSizes += '<div class="size_select_wrapper">';
    selectSizes += '<div class="choices_list size_list swiper-wrapper">';
    var sizes = product.sizes;
    if (product.sizesRegroupRef.length > 0) {
        var sizes = product.sizesRegroupRef;
    }
    if (sizes.length > 0) {
        for (i = 0; i < sizes.length; i++) {
            var size = sizes[i];
            selectSizes += '<div class="form_itm swiper-slide check size">';
            selectSizes += '<input id="size_' + product.id + '_' + size.id + '" type="radio" name="itm_size_' + product.id + '" value="' + size.id + '" data-nom="' + size.label + '" onchange="changeSizeAchatExpressV1(' + product.id + ', ' + size.id + ', ' + size.has_stock + ', ' + product.flagAlertStock + ')" class="input_check radio prodSize size">';
            if (sizes.length > 1) {
                selectSizes += '<label for="size_' + product.id + '_' + size.id + '" title="' + size.label + '"' + ( size.has_stock ? '':' class="disabled" ') + '>'+ size.label + '</label>';
            }
            selectSizes += '</div>';
        }
    }
    selectSizes += '</div></div>';
    $("#lightboxAchatExpressV1ColorSize").append(selectSizes);
    if (sizes.length > 1) {
        var size_swiper_new = new Swiper("#lightboxAchatExpressV1ColorSize .size_select_wrapper", {
            slidesPerView: "auto",
        });
    }
    
}

function updateSizeMobile(product) {
    // SIZES - MOBILE
    var selectSizes = '';
    var sizes = product.sizes;

    $("#display_sizes").html('<label class="preview_size">' + Translator.translate('size') + '</label>');
    if (sizes.length > 0) {
        for (i = 0; i < sizes.length; i++) {
            var size = sizes[i];
            var disabled = '';

            if (!size.has_stock) {
                disabled = 'disabled';
            }

            selectSizes += '<div class="form_itm check size" onclick="changeSizeAchatExpressMobileV1(' + product.id + ', ' + size.id + ', ' + size.has_stock + ', \'' + size.label + '\', ' + product.flagAlertStock + ')">';            selectSizes += '<input id="size_' + product.id + '_' + size.id + '" type="radio" name="itm_size_' + product.id + '" value="' + size.id + '" data-nom="' + size.label + '" class="'+ disabled +'">';
            selectSizes += '<label id="label_' + product.id + '_' + size.id + '" for="size_' + product.id + '_' + size.id + '" title="' + size.label + '"><span>' + size.label + '</span></label>';
            selectSizes += '</div>';
        }
    }

    $("#choose_sizes .choices_list").html(selectSizes);

    if (sizes.length === 1) {
        $("#display_sizes").addClass('disabled').attr('onclick', "");
        changeSizeAchatExpressMobileV1(product.id, sizes[0].id, sizes[0].has_stock, sizes[0].label, product.flagAlertStock);
    } else {
        $("#display_sizes").removeClass('disabled').attr('onclick', "displayColorSizesV1('size')");
        $('#bloc_add_basket_aev1').addClass('disabled');
    }
}

function changeSizeAchatExpressV1(product_id, size_id, has_stock, flagAlertStock = 0) {
    var color_id = $("#lightboxAchatExpressV1 input[name='itm_color_" + product_id + "']:checked").val();
    var responseWP = ajax_file(path_relative_root + 'get_user_wishlist.php?product_id=' + product_id + '&color_id=' + color_id + '&size_id=' + size_id);

    try {
        responseWP = JSON.parse(responseWP);
        if (responseWP.length > 0) {
            $("#lightboxAchatExpressV1Brand .wishlist a").addClass('existToWishlistButton');
        } else {
            $("#lightboxAchatExpressV1Brand .wishlist a").removeClass('existToWishlistButton');
        }
    } catch (e) {
        console.warn('cannot parse responseWp')
    }

    if (!$("#lightboxAchatExpressV1").hasClass('lookbook')) {
        if (has_stock) {
            $('#bloc_add_basket_aev1').slideDown('fast');
            $('#bloc_add_alert_aev1').slideUp('fast');
            $('#size_not_available_aev1').slideUp('fast');
            $('#bloc_add_basket_aev1').removeClass('disabled');
        } else {
            if (flagAlertStock === 1) {
                $('#bloc_add_alert_aev1').slideDown('fast');
                $('#bloc_add_alert_aev1 .bloc_add_alert_form, .alert_available').show();
                $("#bloc_add_alert_aev1 .alert_stock").slideUp('fast');
                $("#bloc_add_alert_aev1 .bloc_add_alert_form, .alert_available").slideDown('fast');
                $('#bloc_add_alert_aev1 .bloc_add_alert_confirmation').hide();
                $('#bloc_add_alert_aev1 .bloc_add_alert_erreur').hide();
                $('#bloc_add_basket_aev1').slideUp('fast');
                showAlertStockV1();
            } else {
                $('#size_not_available_aev1').slideDown('fast');
                $('#bloc_add_basket_aev1').slideUp('fast');
            }
        }
    }
}

function changeSizeAchatExpressMobileV1(product_id, size_id, has_stock, size_label, flagAlertStock = 0) {
    changeSizeAchatExpressV1(product_id, size_id, has_stock, flagAlertStock);
    $("#display_sizes .preview_size").html(size_label);

    if (!has_stock) {
        $("#display_sizes .preview_size").html(size_label + '-' + Translator.translate('stock_epuise_txt')).addClass('disabled');
    } else {
        $("#display_sizes .preview_size").html(size_label).removeClass('disabled');
    }

    $("#size_" + product_id + '_' + size_id).prop("checked", true);
    hideColorSizesV1();
    
    if ($('#display_sizes').hasClass('error')) {
        $('#display_sizes').removeClass('error');
    }
}

function showAlertStockV1() {
    $("#bloc_add_alert_aev1 .alert_stock").slideUp('fast');
    $("#bloc_add_alert_aev1 .bloc_add_alert_form").slideDown('fast');
}

function sendMailAlertStockProductV1(type_alert) {

    var prodId = $("#bloc_add_alert_aev1").data("produitid");
    var tailleId = $("#lightboxAchatExpressV1 input[name='itm_size_" + prodId + "']:checked").val();
    var couleurId = $("#lightboxAchatExpressV1 input[name='itm_color_" + prodId + "']:checked").val();

    var idDiv = "mail_alerte_stock_aev1";
    var idDivAlert = "bloc_add_alert_aev1";

    var email = $("#" + idDiv).val();
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // REGEX pour les mails

    if (email === '' || !email.match(mailformat)) {
        $("#" + idDiv).addClass('inputErr');
        $('#' + idDivAlert + ' .bloc_add_alert_erreur').show();
    } else {
        $("#" + idDiv).removeClass('inputErr');
        $.ajax({
            type: 'post',
            url: path_relative_root + 'ajax_mail_alert_stock.php',
            data: {
                email: email,
                produit_id: prodId,
                taille_id: tailleId,
                couleur_id: couleurId,
                type_alert: type_alert
            },
            success: function (response) {
                $('#' + idDivAlert + ' .alert_stock').hide();
                $('#' + idDivAlert + ' .bloc_add_alert_erreur').hide();
                $('#' + idDivAlert + ' .bloc_add_alert_form').hide();
                $('#' + idDivAlert + ' .bloc_add_alert_confirmation').show();
                $('#' + idDivAlert + ' .alert_return').show();

                setTimeout(function () {
                    $('#' + idDivAlert + ' .bloc_add_alert_confirmation').hide();
                    $('#' + idDivAlert + ' .alert_return').hide();
                }, 5000);
            },
            fail: function () {
                $('#' + idDivAlert + ' .bloc_add_alert_confirmation').hide();
                $('#' + idDivAlert + ' .bloc_add_alert_erreur').show();
                $('#' + idDivAlert + ' .bloc_add_alert_form').show();
            }
        });
    }
}

function addToBasketV1() {    
    var isMobile = $("body").hasClass("mobile");
    var prodId = $("#formAchatExpressV1").attr('data-produitid');
    var tailleId = $("#lightboxAchatExpressV1 input[name='itm_size_" + prodId + "']:checked").val();
    var couleurId = $("#lightboxAchatExpressV1 input[name='itm_color_" + prodId + "']:checked").val();
    var prixU = $("#price_aev1").val();
    var qty = 1;
    var panierId = $("#lightboxAchatExpressV1 input[name='panierId']").val();

    var data = {
        panierId: panierId,
        idprod: prodId,
        idcouleur: couleurId,
        idtaille: tailleId,
        qte: qty,
        prixu: prixU
    }

    if (isMobile) {
        $.ajax({
            url: path_relative_root + 'ajax_upd_panier.php',
            type: 'get',
            data: data,
            success: onCartUpdateBatch.bind(this)
        });
        $("#lightboxAchatExpressV1").removeClass("for_tag_button");
        $("body").removeClass("noscroll");
        $("#formAchatExpressV1 .loader").addClass('loading');

        if (!tailleId) {
            $(this).parents('#formAchatExpressV1').find('.display_sizes').addClass('error');
        } else {
            $(this).parents('#formAchatExpressV1').find('.display_sizes').removeClass('error');
        }

        setTimeout(function () {
            $("#formAchatExpressV1 .loader").removeClass('loading');

            if ($('body').hasClass('cart')) {
                location.reload();
            }
        }, 1000);

    } else {
        $("#formAchatExpressV1 .loader").addClass('loading');

        var res = ajax_file(path_relative_root + 'ajax_upd_panier.php?' + $.param(data));
        if (res) {
            try {
                var response = JSON.parse(res);
            } catch (e) {}
            if (typeof response !== "undefined" && typeof response.error !== "undefined") {
                alert(response.error);
            } else {
                // Mise à jour du panier top
                var response_basket = ajax_file(path_relative_root + "ajax_show_basket.php");
                $("#cart_top").html(response_basket);
                OverlayScrollbars(document.querySelector('#cart_top .cart_product_listing'), {});
                $('body').removeClass('noscroll');
                $("#lightboxAchatExpressV1").slideUp().removeClass('actif');
                CartShow();
                $('#bloc_add_basket_aev1').addClass('disabled');
            }

            setTimeout(function () {
                $("#formAchatExpressV1 .loader").removeClass('loading');
            }, 500);
        }
    }
}

function displayColorSizesV1(type)
{
    var id = 'choose_' + type + 's';
    $("#lightboxAchatExpressV1ColorSize .product_fields").removeClass("actif");
    $(".divToHide").hide();
    $("#lightboxAchatExpressV1ColorSizeMobile").show();
    $("#" + id).addClass("actif");
}

function hideColorSizesV1()
{
    $("#lightboxAchatExpressV1Btn").show();
    $("#lightboxAchatExpressV1 .top_wrapper").show();
    $("#lightboxAchatExpressV1ColorSizeMobile").hide();
    $("#lightboxAchatExpressV1ColorSize .product_fields").addClass("actif");
    $("#lightboxAchatExpressV1ColorSizeMobile .product_fields").removeClass("actif");
}

function addToWishlist_mobile(wishlist_checkbox)
{
    var form = document.getElementById('formAchatExpressV1'),
        itm_id = $("#formAchatExpressV1").attr('data-produitid'),
        value = getInputVal.bind(form),
        head_offset = (window.innerWidth * 0.2921875),
        data, has_errors;

    if (!itm_id) {

        return false;
    }

    var btn = $(this);

    data = {
        mobile: 1,
        product_id: itm_id,
        size_id:$("#lightboxAchatExpressV1 input[name='itm_size_" + itm_id + "']:checked").val(),
        color_id: $("#lightboxAchatExpressV1 input[name='itm_color_" + itm_id + "']:checked").val(),
        need_color_id: !!form.itm_colour,
        need_size_id: true,
        is_model: value('itm_is_model')
    };

    // On regarde si on est en mode creation
    if (btn.attr('data-action') == 'create') {

        var input_create = $('input[name="wishlist_name"]');

        // En mode creation on regarde si on a rentré un nom
        if (input_create.val().length < 3) {
            input_create.addClass('inputErr');
            $('#err_create_wishlist').html(Translator.translate('err_create_wishlist_length'));

            return;
        } else {
            input_create.removeClass('inputErr');
            $('#err_create_wishlist').html('');
        }
        data.wishlist_nom = input_create.val();
    } else if (btn.attr('data-action') == 'modify') {
        data.wishlist_id = wishlist_checkbox.val();
    }

    has_errors = false;

    if ((data.need_color_id && !data.color_id) || (data.need_size_id && !data.size_id)) {

        if (!data.color_id) {

            form.itm_colour.classList.add('error');
        }

        if (!data.size_id) {
            $('#display_sizes').addClass('error');
        }

        return false;
    }

    // On regarde si la checkbox est coché ou non
    // Si on pase une checkbox alors on regarde si elle est coché
    // Si elle est coché alors le produit est dans la wishlist

    if (wishlist_checkbox != undefined && !wishlist_checkbox.is(':checked') || $('a#addToWishlistButton').hasClass('existToWishlistButton')) {
        // Le produit a supprimer de la wishlist
        data.id = data.product_id;
        // On va retirer le produit de la wishlist
        $.post(path_relative_root + 'ajax_remove_from_wishlist.php?time=' + new Date().getTime(), data, function(resp) {

            if (resp.error) {
                if (resp.message == "login_required") {
                    openModalBox.call(document.getElementById('alertboxlogin2'));
                } else {
                    if (resp.message != "error") {
                        $('#alert_box .alert_box_content').html(Translator.translate('product_take_off'));
                    } else {
                        $('#alert_box .alert_box_content').html(Translator.translate(resp.result.error_message));
                    }
                    openModalBox.call(document.getElementById('alert_box'));
                }
            } else {
                // On décoche la checkbox
                $('a#addToWishlistButton').removeClass("existToWishlistButton").addClass("addToWishlistButton");

                if (wishlist_checkbox != undefined) {
                    wishlist_checkbox.prop('checked', false);
                    wishlist_checkbox.closest('.wishlist_item').find('.wishlist_add_report').html('<span class="delete">' + Translator.translate('product_take_off') + '</span>');
                }
            }
        });
    } else {
        $.ajax({
            url: path_relative_root + 'ajax_add_to_wishlist.php?time=' + Date.now(),
            type: 'post',
            data: data,
            success: function(res) {
                btn.next('.loader').removeClass('loading');
                if (res.error) {

                    if (res.message == "login_required") {

                        modBoxOpen.call(document.getElementById('alertboxlogin2'));
                        $('#lightboxAchatExpressV1').slideUp();
                        $('body').removeClass('noscroll');
                    } else if (res.message != "error") {

                        $('#alert_box .alert_box_content').html(Translator.translate('add_wishlist_error_'+res.message));
                        modBoxOpen.call(document.getElementById('alert_box'));
                    } else {

                        $('#alert_box .alert_box_content').html(Translator.translate(res.result.error_message));
                        modBoxOpen.call(document.getElementById('alert_box'));
                    }
                } else {
                    //evenement DataLayer
                    if (window.dataLayer !== undefined) {
                        window.dataLayer.push({
                            'event': 'addToWishlist',
                            'products': {
                                'product_id': value('itm_id'),
                                'product_ref': value('itm_ref'),
                            }
                        });
                    }

                    var dl_v3_obj = res.result.dlv3_wishlist_item;

                    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && Object.keys(dl_v3_obj).length > 0) {
                        DataLayerTrigger.addToWishList(dl_v3_obj['items'], dl_v3_obj['actionField'])
                    }

                    // Cas de la création
                    if (btn.attr('data-action') == 'create') {
                        res.result.checked = true;
                        buildWhislitList(res.result);
                    } else if (data.wishlist_id != null) {
                        // On affiche que le produit a été rajouté
                        $('#wishlist_' + data.wishlist_id).closest('.wishlist_item').find('.wishlist_add_report').html('<span class="success">' + translate('product_add') + '</span>');
                    } else {
                        if ($('a#addToWishlistButton').length) {
                            $('a#addToWishlistButton').removeClass('addToWishlistButton').addClass('existToWishlistButton');
                        } else {
                            $('#addToWishlistButton').removeClass('addToWishlistButton').addClass('existToWishlistButton');
                        }
                        
                        $('#addToWishlistButtonTxt').html(Translator.translate('exist_to_wishlist_button_label'));
                    }
                }
            }
        });
    }
}