function addLookToWishlist()
{
    var btn = $("#addToWishlistButton");
    var products = [];
    var colors = [];
    var sizes = [];
    var needSizeId = [];
    var needColorId = [];

    var nbProducts = $("#products_look input[name='nb_products']").val();

    var i = 0;
    //get all product
    for (i = 0; i < nbProducts; i++) {
        var productId = $("#products_look input[name='products_" + i + "_product']").val();
        var colorId = $("#products_look input[name='products_" + i + "_color']").val();
        var sizeId = $("#products_look select[name='products_" + i + "_size']").val();
        if (!userWishlist.includes(productId + "_" + sizeId + "_" + colorId)) {
            products.push(productId);
            sizes.push(sizeId);
            colors.push(colorId);
            needSizeId.push("true");
            needColorId.push("true");
        }
    }

    if (products.length) {
        var data = {
            product_id: products,
            color_id: colors,
            size_id: sizes,
            need_size_id: needSizeId,
            need_color_id: needColorId
        };
        $.post(path_relative_root + "ajax_add_look_to_wishlist.php", data, function(resp) {
            if (resp.error == 0) {
                btn.addClass("existToWishlistButton");        
                btn.attr('onclick','removeLookFromWishlist()');
            } else {
                alert(Translator.translate('error_insert_into_wishlist'));
            }
        });
    } else {
        btn.addClass("existToWishlistButton");
        btn.attr('onclick','removeLookFromWishlist()');
    }
}

function removeLookFromWishlist()
{
    var btn = $("#addToWishlistButton");
    var nbProducts = $("#products_look input[name='nb_products']").val();

    if (nbProducts) {
        var i = 0;
        var error = false;
        for (i = 0; i < nbProducts; i++) {
            var productId = $("#products_look input[name='products_" + i + "_product']").val();
            var colorId = $("#products_look input[name='products_" + i + "_color']").val();
            var sizeId = $("#products_look select[name='products_" + i + "_size']").val();
            if (productId) {
                var data = {
                    product_id: productId,
                    color_id: colorId,
                    size_id: sizeId,
                    remove: true
                };
                $.post(path_relative_root + "ajax_remove_from_wishlist.php", data, function(resp) {
                    if (resp.error == 0) {
                        btn.removeClass("existToWishlistButton");
                        btn.attr('onclick','addLookToWishlist()');
                    } else {
                        error = true;
                    }
                });
            }
        }
        setTimeout(function() {
            if (error) {
                alert(Translator.translate('error_remove_from_wishlist'));
            }
        }, 1000);
    } else {
        btn.removeClass("existToWishlistButton");
        btn.attr('onclick','addLookToWishlist()');
    }
}

function addLookToCart() {
    var loader = $("#addLookToBasket").parent().find(".loader");
    var errorBloc = $("#addLookToBasket").parent().find(".error");

    if (typeof loader != "undefined") {
        loader.addClass('loading');
    }

    loader.addClass("loading");
    errorBloc.hide();

    addOneProductToBasket(0);
}

function addOneProductToBasket(i)
{
    var loader = $("#addLookToBasket").parent().find(".loader");
    var errorBloc = $("#addLookToBasket").parent().find(".error");

    if ($("#products_look input[name='products_" + i + "_product']").length) {
        var productId = $("#products_look input[name='products_" + i + "_product']").val();
        var colorId = $("#products_look input[name='products_" + i + "_color']").val();
        var sizeId = $("#products_look select[name='products_" + i + "_size']").val();
        var qte = $("#products_look input[name='products_" + i + "_qte']").val();
        var price = $("#products_look input[name='products_" + i + "_price']").val();
        $.ajax({
            url: path_relative_root + 'ajax_upd_panier.php',
            data: {
                async: false,
                idprod: productId,
                prixu: price,
                panierid: 0,
                qte: qte,
                idcouleur: colorId,
                idtaille: sizeId,
                dataTypeReturn: 'json',
            },
            success : function(res) {
                let data = JSON.parse(res);
                if (!data.error) {
                    productAdded = true;
                    if ($("body").hasClass("mobile")) {
                        document.getElementById("cart_btn").setAttribute("data-cart", data.nb_article);
                    }
                } else if (data.error && data.error.code == 1) {
                    errorOutOfStock = true;
                    errorBloc.show();
                }
            }
        }).done(function() {
            
            var nbProducts = $("#products_look input[name='nb_products']").val();
            i++;
            if (i == nbProducts) {
                var isMobile = $("body").hasClass("mobile");
                if (isMobile) {
                    modBoxOpen.call(document.getElementById("item_added"));
                } else {
                    var response = ajax_file(path_relative_root + 'ajax_show_basket.php');
                    $("#cart_top").html(response);
                    var count_bask = $('#cart_top .cart').attr('data-cart');
        
                    if (count_bask > 0) {
                        CartShow();
                    }
                }
                if (typeof loader != "undefined") {
                    loader.removeClass('loading');
                }
            } else {
                addOneProductToBasket(i);
            }
        });
    }
}

function joinFidelityProgram() {
    var form = $("#joinFidelityProgramForm");
    $("#joinFidelityProgramForm .w-error").hide();
    $("#joinFidelityProgramForm .w-loader").addClass('loading');
    var checkbox = document.querySelector('input[name="optinFid"]');
    
    if (!(checkbox.checked)) {
        // Show error
        if(!$('#joinFidelityProgramForm .error_msg').length) {
            $("#joinFidelityProgramForm").append('<span class="error_msg">' + Translator.translate('error_submit_fid') + '</span>');
        }
        //Reset loader
        $("#joinFidelityProgramForm .w-loader").removeClass('loading');
    } else {
        $.ajax({
            type: 'post',
            data: form.serialize(),
            url: path_relative_root + 'ajax_join_fidelity_program.php',
            success: function (res) {
                if (res.success) {
                    if ($("body").hasClass("customer")) {
                        window.location.reload();
                    } else {
                        window.location.href = path_relative_root + create_link("client_fidelity");
                    }
                } else if (res.redirect_login) {
                    window.location.href = path_relative_root + create_link("connexion_login");
                } else {
                    $("#joinFidelityProgramForm .w-error").show();
                }
    
                setTimeout(function() {
                    $("#joinFidelityProgramForm .w-loader").removeClass('loading');
                }, 500);
            }
        });
    }
}

/*$(window).on("applepay_hipay_button", function () {
    $('#hipay-apple-pay-button').click();
});*/

$(window).on("paypal_remote_button", function () {
    $('.payment_mode_btn[data-payment-mode = "paypal_remote"]').addClass('loading');
});

$(window).on("search_success", function () {
    scroolToProduct();
});

function deleteItemFromCart (id, qty) {
    if (typeof dataLayer_v3 !== 'undefined' && typeof dl_v3_impressions !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && id !== null && id !== undefined) {
        DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, id, "remove_from_cart", qty);
    }
}

$(function () {
    if($("#btn_pwd").length) {
        $("#clientForm").on("submit", function() {
            $("#btn_pwd").addClass("loader");
            setTimeout(function() {
                $("#btn_pwd").removeClass("loader");
            },2000);
        });
    }

    $(".basket_contents").on('click', '.input_product_options', function() {
        var parentBasketId = $(this).data('parent-basket-id');
        var basketId = $(this).data('basket-id');
        var productId = $(this).data('product-id');
        if (parentBasketId && productId) {
            updateOption.call(this, parentBasketId, basketId, productId);
        }
    });

    if ($("body").hasClass("product_page") || $("body").hasClass("cart step_1")) {
        if ( $(".product_option").length) {
            getOptStock();
        }
    }
});

function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();
    if (typeof selectId !== 'undefined') {
        if (selectId.includes("return_quantity")) {
            valueIdTailleQuantity = selectId.split("return_quantity_")[1];
            if (selectedValue != 0) {
                $('#' + selectId).addClass('choice_locked');
                if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                    $('#' + selectId).addClass('choice_unlocked_both');
                }
            } else {
                if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                    $('#' + selectId).removeClass('choice_locked');
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                    $('#' + selectId).addClass('choice_unlocked_both');
                    if (document.querySelector('.choice_locked_both')) {
                        $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                        $('#' + selectId).addClass('choice_locked_both');
                        $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                        $('#' + selectId).removeClass('choice_unlocked_both');
                    }
                } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                    $('#' + selectId).removeClass('choice_locked');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                    $('#return_choice_' + valueIdTailleQuantity).val(0);
                    $('#' + selectId).addClass('choice_unlocked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                    $('#' + selectId).removeClass('choice_locked_both');
                    if (document.querySelector('.choice_locked_both')) {
                        $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                        $('#' + selectId).addClass('choice_locked_both');
                        $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                        $('#' + selectId).removeClass('choice_unlocked_both');
                    }
                } 
            }
        } else if (selectId.includes("return_choice")) {
            valueIdTailleChoice = selectId.split("return_choice_")[1];
            if (selectedValue != 0) {
                $('#' + selectId).addClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
                $('#' + selectId).addClass('choice_locked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
                $('#' + selectId).removeClass('choice_unlocked_both');
            } else {
                if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                    $('#' + selectId).removeClass('choice_locked');
                    $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                    $('#' + selectId).addClass('choice_unlocked_both');
                    $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                    $('#' + selectId).removeClass('choice_locked_both');
                } 
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $("select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement); 
    });

    initDailymotionVideos();
});

function searchSizeSort(sizes) {
    return sizes.sort(function(a, c) {
        if (typeof a.order === "undefined") {
            return a.label < c.label ? -1 : 1;
        } else {
            return parseInt(a.order) < parseInt(c.order) ? -1 : 1;
        }
    });
}

function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");
    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function () {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

/***
 *Client disconnexion
 */
 function ajaxDeconnect(path_relative_root) {

    var provenance = window.location.pathname,
        cpt_client = new RegExp(/(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/),
        response;

    // Get the current page's name
    provenance = provenance.substr(provenance.lastIndexOf('/'), provenance.lastIndexOf('.'));

    // This actually disconnects the client
    response = ajax_file(path_relative_root + 'ajax_deconnect.php');

    // If there was an error
    if (!response)
        alert('Erreur lors de la déconnexion.');

    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href = path_relative_root + create_link('connexion_login');

    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

async function addConfiguredProduct() {
    var productId = $('#produit_id').val();
    var colorId = ($('#couleurProd').length > 0) ? $('#couleurProd').val() : $('#prod_couleur').val();
    var sizeId = ($('#tailleProd').length > 0) ? $('#tailleProd').val() : $('#prod_taille').val();

    let checkboxes = document.getElementsByName('options');
    var configuredProductIds = [];
    for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
            configuredProductIds.push(checkboxes[i].value);
        }
    }
    $.ajax({
        url: path_relative_root + "ajax_upd_panier.php",
        type: "post",
        dataType: 'json',
        data: {
            configurator: true,
            idprod: productId,
            idcouleur: colorId,
            idtaille: sizeId,
            panierid: 0,
            qte: 1,
            configurator_product: false,
            dataTypeReturn: 'json',
            reinit_basket_virtuel_parent_id: true,
            virtual_basket: true,
        },
        success: async function (res) {
            try {
                var virtualBasketId = res.panier_virtuel_id;
                var nbOpts = configuredProductIds.length;
                if (res.error == 0) {
                    for (var i = 0; i < nbOpts; i++) {
                        await $.ajax({
                            url: path_relative_root + "ajax_upd_panier.php",
                            type: "post",
                            dataType: 'json',
                            data: {
                                configurator: true,
                                idprod: configuredProductIds[i],
                                idcouleur: 0,
                                idtaille: 0,
                                basket_virtuel_parent_id: res.panier_virtuel_id,
                                qte: 1,
                                configurator_product: true,
                                dataTypeReturn: 'json',
                                reinit_basket_virtuel_parent_id: false,
                            },
                            success: function (res) {
                                try {
                                    if (res.error == 0) {

                                        if (i == (configuredProductIds.length - 1)) {
                                            if ($('body').hasClass('mobile')) {
                                                $("#item_added").addClass('open');
                                                modBoxOpen.call(document.getElementById("#item_added"));
                                            } else {
                                                //update top cart
                                                var response = ajax_file(path_relative_root + "ajax_show_basket.php");
                                                cart = Translator.translate('mon_panier');
                                                document.getElementById('cart_top').innerHTML = response;
                                                var total = $('#cart_top .cart').attr('data-cart');

                                                if (total <= 1) {
                                                    var text = Translator.translate('one_product');
                                                } else {
                                                    var text = Translator.translate('2_product');
                                                }

                                                $('.cart_main_title.basket').html(cart + ' ' + '<span class="nb_products">(' + total + ' ' + text + ')</span>');
                                                CartShow();
                                            }
                                        }
                                        return true;
                                    } else {
                                        removeOptFromBasket(virtualBasketId);
                                        i = nbOpts + 1;
                                        alert(res.error.msg);
                                    }
                                    return false;
                                } catch (e) {
                                    console.error('JSON ERROR: could not parse data');
                                    return false;
                                }
                            }
                        });
                    }
                } else {
                    alert(res.error.msg);
                }
            } catch (e) {
                console.error('JSON ERROR: could not parse data');
                return false;
            }
        }
    });
}

function updateOption(parentBasketId, basketId, productId)
{
    let checked = this.checked;
    if (checked) {
        $.ajax({
            url: path_relative_root + "ajax_upd_panier.php",
            type: "post",
            dataType: 'json',
            data: {
                configurator: true,
                idprod: productId,
                idcouleur: 0,
                idtaille: 0,
                basket_virtuel_parent_id: parentBasketId,
                qte: 1,
                configurator_product: true,
                dataTypeReturn: 'json',
                reinit_basket_virtuel_parent_id: false,
            },
            success: function (res) {
                try {
                    if (res.error == 0) {
                        if (!$('body').hasClass('mobile')) {
                            var response = ajax_file(path_relative_root + "ajax_show_basket.php");
                            document.getElementById('cart_top').innerHTML = response;
                        }
                        reloadLeftAndRightTunnel({
                            returnHtml : "ajax"
                        }, false);
                        return true;
                    } else {
                        alert(res.error.msg);
                    }
                    return false;
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }
            }
        });
    } else {
        // remove product from basket
        $.ajax({
            url: path_relative_root + "ajax_removeBasketProd.php",
            type: "post",
            dataType: 'json',
            data: {
                basket_id: basketId,
                prod_id: productId,
                qte: 1,
                dataTypeReturn: 'json',
            },
            success: function (res) {
                try {
                    $('#cart_top').html(res.datas.html);
                    reloadLeftAndRightTunnel({
                        returnHtml : "ajax"
                    }, false);
                    return true;
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }
            }
        });
    }
}

function removeOptFromBasket(virtualBasketId)
{
    $.ajax({
        url: path_relative_root + "ajax_removeBasketProd.php",
        type: "post",
        dataType: 'json',
        data: {
            basket_id: virtualBasketId,
            qte: 1,
            dataTypeReturn: 'json',
        }
    });
}

/**
 * Get stock of all product of the page
 */
function getOptStock()
{
    var productIdsAll = $.find('.product_option');
    var productIdsNew = new Array();

    if (productIdsAll.length) {
        for (var indexId = 0; indexId < productIdsAll.length; indexId++) {
            var currentProductId = $(productIdsAll[indexId]).attr('data-id');
            if (!productIdsNew.includes(currentProductId)) {
                productIdsNew.push(currentProductId);
            }
        }
    }

    $.ajax({
        url : path_relative_root + 'ajax_get_colisages_states_by_color_size.php',
        type : 'post',
        data : {produit_id: productIdsNew},
        success : function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");
                return;
            }

            if (res.success && res.result) {
                var result = res.result;

                // On stock les informations dans le tableau JS
                Object.entries(result).forEach(entry => {
                    const [idProduct, objProduct] = entry;
                    var qty = objProduct.stock_quantite;
                    if (qty > 0) {
                        $(".product_option[data-id='" + idProduct + "']").show();
                    } else {
                        $(".product_option[data-id='" + idProduct + "']").hide();
                    }
                });
            }
        }
    });
}